<template>
  <Vue3Marquee
    data-component-name="PartnersMarquee"
    :duration="88"
    clone
  >
    <NuxtImg
      v-for="props of partners"
      v-bind="props"
      :style="invert
        ? { filter: `invert(${Number(invert)}) brightness(2)` }
        : {}
      "

      densities="x1 x2"
      format="webp"
    />
  </Vue3Marquee>
</template>

<script setup lang="ts">
import { Vue3Marquee } from 'vue3-marquee';

withDefaults(
  defineProps<{
    background?: '#F8F9FA' | 'transparent'
    invert?: boolean
  }>(),
  {
    background: '#F8F9FA',
    invert: false,
  },
);

// Note: Please keep order alphabetical
const partners = [
  {
    src: '/redesign/images/logos/Adistec_bw.webp',
    width: '102',
    height: '28',
    alt: '',
  },
  {
    src: '/redesign/images/logos/Anm_bw.webp',
    width: '129',
    height: '41',
    alt: '',
  },
  {
    src: '/redesign/images/logos/Arrow_bw.webp',
    width: '137',
    height: '28',
    alt: '',
  },
  {
    src: '/redesign/images/logos/BlueAlly_bw.webp',
    width: '125',
    height: '71',
    alt: '',
  },
  {
    src: '/redesign/images/logos/CDW_bw.webp',
    width: '131',
    height: '66',
    alt: '',
  },
  {
    src: '/redesign/images/logos/Compunet_bw.webp',
    width: '112',
    height: '28',
    alt: '',
  },
  {
    src: '/redesign/images/logos/Connection_bw.webp',
    width: '105',
    height: '41',
    alt: '',
  },
  {
    src: '/redesign/images/logos/ConvergeOne_bw.webp',
    width: '184',
    height: '28',
    alt: '',
  },
  {
    src: '/redesign/images/logos/Copaco_bw.webp',
    width: '113',
    height: '28',
    alt: '',
  },
  {
    src: '/redesign/images/logos/Datta_bw.webp',
    width: '133',
    height: '45',
    alt: '',
  },
  {
    src: '/redesign/images/logos/ExertisCybersecurity.webp',
    width: '240',
    height: '28',
    alt: '',
  },
  {
    src: '/redesign/images/logos/GHATechnologies_bw.webp',
    width: '168',
    height: '54',
    alt: '',
  },
  {
    src: '/redesign/images/logos/IntegraOne_bw.webp',
    width: '133',
    height: '47',
    alt: '',
  },
  {
    src: '/redesign/images/logos/LevelSolutionsGroup_bw.webp',
    width: '145',
    height: '57',
    alt: '',
  },
  {
    src: '/redesign/images/logos/Logicalis_bw.webp',
    width: '316',
    height: '28',
    alt: '',
  },
  {
    src: '/redesign/images/logos/MicroAge_bw.webp',
    width: '141',
    height: '61',
    alt: '',
  },
  {
    src: '/redesign/images/logos/Mirazon_bw.webp',
    width: '140',
    height: '28',
    alt: '',
  },
  {
    src: '/redesign/images/logos/Presidio_bw.webp',
    width: '207',
    height: '28',
    alt: '',
  },
  {
    src: '/redesign/images/logos/Prodatix_bw.webp',
    width: '111',
    height: '28',
    alt: '',
  },
  {
    src: '/redesign/images/logos/Sentinel_bw.webp',
    width: '157',
    height: '39',
    alt: '',
  },
  {
    src: '/redesign/images/logos/Softchoise_bw.webp',
    width: '132',
    height: '28',
    alt: '',
  },
  {
    src: '/redesign/images/logos/Synnex_bw.webp',
    width: '146',
    height: '28',
    alt: '',
  },
  {
    src: '/redesign/images/logos/V-valey_bw.webp',
    width: '93',
    height: '28',
    alt: '',
  },
  {
    src: '/redesign/images/logos/Verinext_bw.webp',
    width: '135',
    height: '28',
    alt: '',
  },
  {
    src: '/redesign/images/logos/VLCM_bw.webp',
    width: '106',
    height: '36',
    alt: '',
  },
  {
    src: '/redesign/images/logos/Waident_bw.webp',
    width: '106',
    height: '36',
    alt: '',
  },
  {
    src: '/redesign/images/logos/WorldWideTechnologies_bw.webp',
    width: '172',
    height: '36',
    alt: '',
  },
];
</script>

<style scoped lang="scss">
@import "$/functions/token";

[data-component-name="PartnersMarquee"] {
  height: 7.75rem !important;
  gap: 2rem;

  background-color: v-bind(background);

  &:deep(.marquee) {
    gap: 2rem;
  }
}
</style>
